import {afterNextRender, inject, Inject, Injectable, signal, Signal, WritableSignal} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {BehaviorSubject, firstValueFrom, lastValueFrom, Observable} from 'rxjs';
import {ILanguage} from '../interfaces/language.interface';
import {TFbrLang} from '../enum/lang.enum';
import {LocalStorageKeys} from '../enum/local-storage-keys.enum';
import {toSignal} from '@angular/core/rxjs-interop';
import {LocalStorage} from '../tokens/local-storage';

export const DEFAULT_LOCALE = TFbrLang.en;

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  router = inject(Router);
  public supportedLanguages: ILanguage[] = [{
    locale: TFbrLang.en,
    title: 'English',
    metaDescription: 'Manage your poker ranges in Editor. Learn to memorize your strategy with Trainer. View your preflop charts in Viewer. All in your browser.',
    canonicalLink: 'https://freebetrange.com/en'
  }, {
    locale: TFbrLang.ru,
    title: 'Русский',
    metaDescription: 'Создавайте свои префлоп диапазоны. Тренируйтесь в запоминании своей стратегии. Просматривайте префлоп чарты в удобном виде. Все в вашем браузере.',
    canonicalLink: 'https://freebetrange.com/ru'
  }, {
    locale: TFbrLang.es,
    title: 'Español',
    metaDescription: 'Administra tus rangos de poker en el Editor. Aprende a memorizar tu estrategia con el Entrenamiento. Usa tus gráficos de manos en la sesión con el Viewer.',
    canonicalLink: 'https://freebetrange.com/es'
  }, {
    locale: TFbrLang.it,
    title: 'Italiano',
    metaDescription: 'Gestisci i tuoi range nell\'Editor. Impara e memorizza la strategia con il Trainer. Usa le tabelle in sessione con il visualizzatore di range.',
    canonicalLink: 'https://freebetrange.com/it'
  }, {
    locale: TFbrLang.pt,
    title: 'Português',
    metaDescription: 'Faça a gestão do seus ranges no Editor. Aprenda e memorize suas estratégias com o Trainer. Use seus ranges in-game com o Visualizador.',
    canonicalLink: 'https://freebetrange.com/pt'
  }, {
    locale: TFbrLang.fr,
    title: 'Français',
    metaDescription: 'Gérez vos ranges de poker dans l\'éditeur. Mémorisez votre stratégie avec l\'entraîneur. Appliquez dans le jeu en utilisant la vue.',
    canonicalLink: 'https://freebetrange.com/fr'
  }, {
    locale: TFbrLang.zh,
    title: '中文',
    metaDescription: '在编辑器中管理您的扑克范围。使用训练器学习和记忆您的策略。在游戏中使用您的手牌图表。',
    canonicalLink: 'https://freebetrange.com/zh'
  }];



  private _currentLanguage :WritableSignal<TFbrLang> = signal(DEFAULT_LOCALE);
  private _languageInit: boolean = false;
  currentLanguage: Signal<TFbrLang> = this._currentLanguage.asReadonly();

  constructor(
    private translateService: TranslateService,
    @Inject(LocalStorage) private readonly localStorage: Storage
  ) {

  }


  initLanguage(): Promise<TFbrLang> {
    if (this._languageInit) {
      return new Promise<TFbrLang>((resolve) => resolve(this.getLanguage()));
    } else {
      const storageLanguage: TFbrLang = this.getLocalLanguage();
      if (storageLanguage) {
        return firstValueFrom(this.translateService.use(storageLanguage))
          .then(() => {
            this._currentLanguage.set(storageLanguage);
            this._languageInit = true;
            return storageLanguage;
          });
      } else {
        const language = this.translateService.getBrowserLang() as TFbrLang;
        const isSupported = this.supportedLanguages.findIndex(lang => lang.locale === language) !== -1;
        this._languageInit = true;
        return this.setLanguage(isSupported ? language : DEFAULT_LOCALE);
      }
    }
  }


  getLocalLanguage(): TFbrLang {
    return this.localStorage.getItem(LocalStorageKeys.Language) as TFbrLang;
  }

  getLanguage(): TFbrLang {
    const storageLanguage = this.getLocalLanguage();
    if (storageLanguage) {
      return storageLanguage;
    } else {
      return this.translateService.getDefaultLang() as TFbrLang;
    }
  }

  setLanguage(locale: TFbrLang): Promise<TFbrLang> {
    if (locale && this.supportedLanguages.findIndex(language  => language.locale === locale) !== -1) {
      this.localStorage.setItem(LocalStorageKeys.Language, locale);
      this._currentLanguage.set(locale);
      return firstValueFrom(this.translateService.use(locale))
        .then(() => locale);
    } else {
      this.localStorage.setItem(LocalStorageKeys.Language, DEFAULT_LOCALE);
      this.translateService.setDefaultLang(DEFAULT_LOCALE);
      this._currentLanguage.set(DEFAULT_LOCALE);
      return firstValueFrom(this.translateService.use(DEFAULT_LOCALE))
        .then(() => DEFAULT_LOCALE);
    }
  }
}
